// TODO: remove when IE11 dies
import "react-app-polyfill/ie11";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import ReactModal from "react-modal";
import * as Sentry from "@sentry/react";

import "./util/NoUndefinedClass";
import Widget from "./components/widget/Widget";
import { Lang } from "./components/app/Translate";

Sentry.init({
  dsn: "https://c78be0212381d55ff38b4e206b46bfe3@o4505957043798016.ingest.sentry.io/4506350651506688",
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay(),
  ],

  // https://github.com/getsentry/sentry-javascript/issues/2514#issuecomment-603971338
  beforeSend(event, hint) {
    const error = hint.originalException;
    if (error instanceof Error && error.message && error.message.match(/timeout/i)) {
        return null;
    }
    return event;
  },

  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


const root = document.getElementById("udir_sammenligning_root_8rBHo7hGy");
if (root) {
  ReactModal.setAppElement("#udir_sammenligning_root_8rBHo7hGy");
  ReactDOM.render(<App />, root);
}

const widgets = document.getElementsByClassName("sammenligning-widget");
for (let i = 0; i < widgets.length; i++) {
  const widget = widgets[i];
  ReactDOM.render(
    <Widget
      uno_id={widget.getAttribute("data-uno-id") || ""}
      widget_name={widget.getAttribute("data-widget-name") || ""}
      lang={(widget.getAttribute("lang") as Lang) || "nb"}
    />,
    widget
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
